<!--  -->
<template>
  <div>
    <!-- 顶部 -->
    <van-sticky>
      <div class="point_top">
        <!-- <i class="el-icon-arrow-left" ></i> -->
        <div class="point_kind">门店医保刷卡</div>
        <div class="point_point"></div>
      </div>
    </van-sticky>
    <!-- 查询条件   日期  开始时间  结束时间  门店  刷卡人 必须条件都满足才能查询-->
    <!-- 时间筛选 -->
    <div
      style="
        width: 92%;
        height: 30vw;
        margin-left: 2%;
        padding: 0 2vw;
        background: #fff;
        border-radius: 2vw;
        margin-top: 2vw;
      "
    >
      <!-- 开始时间 -->
      <!-- 门店 -->
      <div class="every_option">
        <div style="display: flex">
          <div style="font-size: 4vw; margin-left: 6vw; font-weight: 600">
            门店
          </div>
        </div>
        <div
          style="
            width: 30vw;
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: -4.5vw;
            font-size: 4vw;
          "
        >
          <!-- <el-select v-model="value" placeholder="请选择门店" size="small">
            <el-option
              style="border: 0; font-size: 4.5vw"
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select> -->
          {{ shopName }}
          <!-- <div style="font-size: 3.4vw; margin-left: 7vw">
            {{ query.endTime ? query.endTime : "点击选择" }}
          </div> -->
        </div>
      </div>
      <div class="every_option">
        <div style="display: flex">
          <div style="font-size: 4vw; margin-left: 6vw; font-weight: 600">
            日期
          </div>
        </div>
        <div
          style="width: 30vw; height: 100%; display: flex; align-items: center"
          @click="chooseDate(1)"
        >
          <div style="font-size: 4vw; margin-left: 4vw">
            {{ query.startTime }}
          </div>
        </div>
      </div>
      <!-- 结束时间 -->
      <!-- <div class="every_option">
        <div style="display: flex">
          <div style="font-size: 4vw; margin-left: 6vw; font-weight: 600">
            结束时间
          </div>
        </div>
        <div
          style="width: 30vw; height: 100%; display: flex; align-items: center"
          @click="chooseDate(2)"
        >
          <div style="font-size: 4vw; margin-left: 8vw">
            {{ query.endTime ? query.endTime : "点击选择" }}
          </div>
        </div>
      </div> -->
      <!-- 刷卡人 -->
      <div class="every_option">
        <div style="display: flex">
          <div style="font-size: 4vw; margin-left: 6vw; font-weight: 600">
            刷卡人
          </div>
        </div>
        <div style="display: flex; align-items: center; margin-right:-4vw">
          <input
            type="text"
            placeholder="请输入刷卡人"
            style=" width: 30vw; font-size: 4vw; border: 0"
            v-model="creditMan"
          />
        </div>
      </div>
    </div>
    <div style="margin-top: 15vw; display: flex; justify-content: center">
      <el-button type="primary" @click="queryEnter()">查询按钮</el-button>
      <el-button @click="back()">返回上级</el-button>
    </div>
    <!-- 查询结果 -->
    <el-table
      v-if="seltList.length > 0"
      height="52vh"
      :data="seltList"
      :header-cell-style="{
        'text-align': 'center',
        background: '#d3d3d3',
        color: 'black',
        'font-size': '18px',
      }"
      size="mini"
      border
      style="
        width: 96%;
        margin-left: 2%;
        margin-top: 5vw;
        font-size: 12px;
        color: black;
      "
    >
      <el-table-column align="center" prop="idNo" label="身份证号" width="120">
      </el-table-column>
      <el-table-column align="center" prop="setlId" label="结算ID" width="120">
      </el-table-column>
      <el-table-column
        align="center"
        prop="clrType"
        label="统筹类型"
        width="100"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="originalBalance"
        label="刷卡前余额"
        width="120"
      >
      </el-table-column>
      <el-table-column align="center" prop="insurancePayment" label="个账">
      </el-table-column>
      <el-table-column align="center" prop="hifpPay" label="统筹">
      </el-table-column>
      <el-table-column
        align="center"
        prop="acctMulaidPay"
        label="供济刷卡总额 "
        width="140"
      >
      </el-table-column>
    </el-table>
    <!-- 查询按钮   返回上级按钮 -->

    <!-- 日期选择 -->
    <van-popup v-model="choose_popup" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :max-date="maxDate"
        @confirm="chooseDateBtn"
        @cancel="cancelBtn"
      />
    </van-popup>
  </div>
</template>

<script>
import qs from "qs";
export default {
  data() {
    return {
      query: {
        startTime: "",
        endTime: "",
      },
      popup_type: 0, //弹出层 1日期筛选 2子表
      choose_type: 0, //弹窗状态 1开始时间 2结束时间
      choose_popup: false,
      maxDate: 0, //时间不超过今天
      currentDate: 0, //用户选中的时间
      showTime: false, //默认表格不显示    查询有数据的时候在显示
      creditMan: "", //刷卡人
      value: "",
      shopName: "",
      seltList: [],
    };
  },
  mounted() {
    this.shopName = JSON.parse(localStorage.getItem("user_info")).shopName;
    console.log(this.shopName, "门店名称");
    let new_time = new Date();
    this.maxDate = new_time;
    this.currentDate = new_time;
    let month = this.currentDate.getMonth() + 1;
      let newMonth = month >= 10 ? month : "0" + month;
      let day = this.currentDate.getDate();
      let newDay = day >= 10 ? day : "0" + day;
      let tie = `${this.currentDate.getFullYear()}-${newMonth}-${newDay}`;
    this.query.startTime=tie
  },
  methods: {
    // 查询接口
    queryEnter() {
      console.log(this.value, "选择的门店");
      console.log(this.creditMan, "输入的刷卡人");
      console.log(this.query.startTime, "选择的开始时间");
      console.log(this.query.endTime, "选择的结束时间");
      if (this.query.startTime == "") {
        this.$toast({
          message: "日期不能为空",
          duration: 1000,
        });
        return false;
      }
      if (this.creditMan == "") {
        this.$toast({
          message: "请输入刷卡人",
          duration: 1000,
        });
        return false;
      }

      this.$axios
        .post(
          this.$api.creditCardQuery,
          qs.stringify({ date: this.query.startTime, name: this.creditMan }),
          {
            fromHeader: {
              "Content-Type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
          }
        )
        .then((res) => {
          console.log(res,"医保刷卡")
          console.log(res.data.list.length,"a")
          if (res.data.code == 0) {
            this.seltList = res.data.list;
          }
          if (res.data.list.length === 0) {
            this.$toast({
          message: "暂无数据",
          duration: 1000,
        });
          }
        });
    },
    // 返回
    back() {
      //this.$router.go(-1);
      this.$router.push("/point");
    },
    cancelBtn() {
      this.choose_popup = false;
    },
    chooseDateBtn() {
      this.choose_popup = false;
      let month = this.currentDate.getMonth() + 1;
      let newMonth = month >= 10 ? month : "0" + month;
      let day = this.currentDate.getDate();
      let newDay = day >= 10 ? day : "0" + day;
      let tie = `${this.currentDate.getFullYear()}-${newMonth}-${newDay}`;
      //   console.log(tie)
      //   console.log(this.currentDate,"时间")

      // let choose_time = this.getYYYYMMDD(this.currentDate)
      if (this.choose_type == 1) {
        //选中开始时间
        this.query.startTime = tie;
        console.log(this.query.startTime, "开始时间");
      } else {
        this.query.endTime = tie;
        console.log(this.query.endTime, "结束时间");
      }
      this.currentDate = new Date();
    },
    //选择开始时间
    chooseDate(e) {
      this.choose_type = e;
      this.choose_popup = true;
      this.popup_type = 1;
    },
  },
};
</script>
<style  scoped>
.point_top {
  width: 100%;
  height: 6vh;
  background-image: linear-gradient(#04a9f3, #4ec5fb);
  color: #ffffff;
  font-size: 4.6vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.point_point {
  font-size: 6vw;
  font-weight: bolder;
  margin-right: 3vw;
}
.point_top i {
  width: 25px;
  height: 20px;
  margin-left: 3vw;
  font-weight: 600;
}
.every_option {
  height: 7vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
}
.every_select {
  display: flex;
  font-size: 3.5vw;
  flex-wrap: nowrap;
}
</style>